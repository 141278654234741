import store from '../store'

export default function (doc, win) {
  const docEl = doc.documentElement
  const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
  const recalc = function () {
    let clientWidth = docEl.clientWidth
    const clientHeight = docEl.clientHeight
    if (!clientWidth || !clientHeight) return
    if (clientWidth >= 981 && clientWidth / clientHeight > 1) {
      store.dispatch('toggleScreen', true)
    } else if ((clientWidth <= 980 && clientWidth / clientHeight > 1) || clientWidth / clientHeight <= 1) {
      store.dispatch('toggleScreen', false)
    }
    if (clientWidth >= 981) {
      clientWidth = 981
    }
  }
  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
}
