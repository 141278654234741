import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    // 屏幕大小区分大屏小屏
    isMaxScreen: null
  },
  actions: {
    toggleScreen ({ commit }, payload) {
      commit('toggle_screen', payload)
    }
  },
  mutations: {
    toggle_screen (state, payload) {
      state.isMaxScreen = payload
    }
  }
})

export default store
