<template>
 <!-- 合作伙伴 -->
  <div class="content-item white">
    <div class="content-items">
      <p class="title">Business Partners</p>
      <div class="service-list partner-list">
        <template v-for="(item, index) in partnerList">
          <div v-if="!isMaxScreen || item !== ''" :key="index" class="partner-item">
            <span v-if="!isMaxScreen && item === ''">More...</span>
            <img v-if="item !== ''" :src="item" alt="">
          </div>
        </template>
      </div>
      <div v-if="isMaxScreen" class="more">More...</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'partner-part',
  data () {
    return {
      partnerList: [
        require('../../assets/image/partner/logo_oppo.png'),
        require('../../assets/image/partner/logo_vivo.png'),
        require('../../assets/image/partner/logo_oneplus.png'),
        require('../../assets/image/partner/logo_realme.png'),
        require('../../assets/image/partner/logo_iqoo.png'),
        ''
      ]
    };
  },
  computed: {
    ...mapState({
      isMaxScreen: state => state.isMaxScreen
    })
  },
  methods: {
    changeIndex (index) {
      this.itemIndex = index;
    }
  }
};
</script>

<style lang="less" scoped>
@media (max-width: 980px) {
  .home {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    .content-item {
      display: flex;
      flex-direction: column;
      .content-items {
        padding-top: 55px;
        box-sizing: border-box;
        p {
          margin: 0;
          padding: 0;
          text-align: left;
          font-size: 32px;
        }
        .title {
          margin-bottom: 24px;
          font-size: 32px;
          line-height: 32px;
          color: #333;
          font-weight: 550;
        }
        .text {
          display: inline-block;
          color: #999;
          font-size: 24px;
          margin-bottom: 11px;
          text-align: left;
        }
        .service-list {
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 20px;
          padding: 36px 0 0 36px;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          // 合作伙伴
          .partner-item {
            width: 134px;
            height: 44px;
            margin: 0px 30px 36px 0;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 24px;
              color: #999;
            }
          }
          .partner-item:nth-child(4n) {
            margin-right: 0;
          }
          .partner-item:nth-child(13) {
            margin-bottom: 0;
          }
          .partner-item:nth-child(14) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media (min-width: 981px) {
  .home {
    width: 100%;
    .main-content {
      width: 100%;
      .white {
        background: white;
      }
      .gray {
        background: #F6F6F6;
      }
      .content-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .content-items {
        width: 1200px;
        padding: 90px 0;
        box-sizing: border-box;
        .title {
          font-size: 36px;
          font-weight: 500;
          color: #111111;
          line-height: 36px;
          text-align: center;
          margin: 0;
        }
        .text {
          display: inline-block;
          font-size: 22px;
          margin-top: 20px;
        }
        .service-list {
          margin-top: 55px;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          // 合作伙伴
          .partner-item {
            width: 196px;
            height: 64px;
            margin: 0px 55px 90px 0;
            text-align: center;
            line-height: 64px;
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 18px;
              color: #999;
            }
          }
          .partner-item:nth-child(5n) {
            margin-right: 0;
          }
        }
        .more {
          width: 100%;
          height: 64px;
          font-size: 24px;
          color: #999;
          text-align: center;
          line-height: 64px;
        }
      }
    }
  }
}
</style>
