import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import resize from './utils/resize'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.min.css'
import './assets/css/font.css'
// import { initSensorsData } from './utils/sensors'
import { Tab, Tabs } from 'vant'

import 'lib-flexible'
Vue.config.productionTip = false
resize(document, window)
Vue.use(VueAwesomeSwiper)
Vue.use(Tab)
Vue.use(Tabs)
// 初始化sensors
// initSensorsData()
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
