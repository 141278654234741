<template>
  <div class="head-content">
    <div class="head" v-if="isMaxScreen">
      <img src="../../assets/image/head/logo.png" alt="" class="logo">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      isMaxScreen: state => {
        return state.isMaxScreen
      },
    })
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@media (max-width: 980px) {
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .head {
    width: 100%;
    height: 88px;
    overflow-y: scroll;
    background: #F2F5F8;
  }
}
@media (min-width: 981px) {
  .head-content {
    background: #fff;
  }
  .head {
    width: 1200px!important;
    height: 80px!important;
    line-height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 160px;
      height: 40px;
    }
  }
}
</style>
