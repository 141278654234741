<template>
  <!-- 平台 -->
  <div class="content-item white">
    <div class="content-items">
      <p class="title">Our Service</p>
      <div class="image-list">
        <div class="image-item" v-for="(item, index) in imageList" :key="index">
          <img
            :src="isMaxScreen ? item.image : item.image1"
            :alt="item.name"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      imageList: [
        {
          image: require('../../assets/image/home/xiaodang_huishou.png'),
          image1: require('../../assets/image/home/xiaodang_huishou@3x.png'),
          url: 'https://cqdanghuan.com/static/index.html#/?channelId=0040963',
          name: '小当回收',
        },
        {
          image: require('../../assets/image/home/xiaodang_jingpai.png'),
          image1: require('../../assets/image/home/xiaodang_jingpai@3x.png'),
          url: 'https://www.xiaodangjingpai.com',
          name: '小当竞拍',
        }
      ],
    }
  },
  computed: {
    ...mapState({
      isMaxScreen: state => {
        return state.isMaxScreen
      }
    })
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
@media (max-width: 980px) {
  .home {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    .content-item {
      display: flex;
      flex-direction: column;
      .content-items {
        padding-top: 55px;
        box-sizing: border-box;
        p {
          margin: 0;
          padding: 0;
          text-align: left;
          font-size: 32px;
          line-height: 32px;
        }
        .title {
          margin-bottom: 22px;
          font-size: 32px;
          line-height: 32px;
          color: #333;
          font-weight: 550;
        }
        .text {
          display: inline-block;
          color: #999;
          font-size: 24px;
          margin-bottom: 11px;
          text-align: left;
        }
        .image-list {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          img {
            width: 345px;
            height: 176px;
          }
        }
      }
    }
  }
}
@media (min-width: 981px) {
  .home {
    width: 100%;
    .main-content {
      width: 100%;
      .white {
        background: white;
      }
      .gray {
        background: #F6F6F6;
      }
      .content-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .content-items {
        width: 1200px;
        padding: 90px 0;
        box-sizing: border-box;
        .title {
          font-size: 36px;
          font-weight: 500;
          color: #111111;
          line-height: 36px;
          text-align: center;
          margin: 0;
        }
        .image-list {
          margin-top: 44px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          .image-item {
            width: 588px;
            height: 300px;
            position: relative;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
