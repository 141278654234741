<template>
  <!-- 服务保障 -->
  <div class="content-item gray">
    <div class="content-items">
      <p class="title">Service Guarantee</p>
      <div class="service-list">
        <div class="service-item" v-for="(item, index) in serviceList" :key="index">
          <img :src="isMaxScreen ? item.image : item.image1" alt="">
          <div class="service-text-box">
            <span class="service-title">{{item.title}}</span>
            <span class="service-descriptor">{{item.text}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      serviceList: [
        {
          image: require('../../assets/image/home/fuwu_zhencheng.png'),
          image1: require('../../assets/image/home/zhencheng@3x.png'),
          title: 'Customer Service',
          text: 'Your Satisfaction Is Our Priority'
        },
        {
          image: require('../../assets/image/home/fuwu_anquan.png'),
          image1: require('../../assets/image/home/anquan@3x.png'),
          title: 'Quality Check',
          text: 'Process Specification, Privacy Security'
        },
        {
          image: require('../../assets/image/home/fuwu_gaoxiao.png'),
          image1: require('../../assets/image/home/gaoxiao@3x.png'),
          title: 'Logistics',
          text: 'Safe and Fast'
        },
        {
          image: require('../../assets/image/home/fuwu_zhuanye.png'),
          image1: require('../../assets/image/home/zhuanye@3x.png'),
          title: 'Professional',
          text: 'Where Professionalism Meets Perfection'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isMaxScreen: state => {
        return state.isMaxScreen
      }
    })
  },
  methods: {
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
@media (max-width: 980px) {
  .home {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    .content-item {
      display: flex;
      flex-direction: column;
      .content-items {
        padding-top: 55px;
        box-sizing: border-box;
        p {
          margin: 0;
          padding: 0;
          text-align: left;
          font-size: 32px;
        }
        .title {
          margin-bottom: 22px;
          font-size: 32px;
          line-height: 32px;
          color: #333;
          font-weight: 550;
        }
        .text {
          display: inline-block;
          color: #999;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 22px;
          text-align: left;
        }
        .service-list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          cursor: pointer;
          .service-item {
            margin-top: 10px;
            width: 345px;
            height: 334px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #fff;
            border-radius: 20px;
            img {
              width: 100%;
              height: 200px;
              margin-bottom: 10px;
            }
            .service-text-box{
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex: 1;
            }
            .service-title {
              margin-bottom: 10px;
              font-size: 26px;
              line-height: 34px;
              color: #333;
              font-weight: 530;
            }
            .service-descriptor{

            }
            span {
              width: 313px;
              margin: 0 16px;
              font-size: 22px;
              line-height: 33px;
              color: #999;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 981px) {
  .home {
    width: 100%;
    .main-content {
      width: 100%;
      .white {
        background: white;
      }
      .gray {
        background: #F6F6F6;
      }
      .content-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .content-items {
        width: 1200px;
        padding: 90px 0;
        box-sizing: border-box;
        .title {
          font-size: 36px;
          font-weight: 500;
          color: #111111;
          line-height: 36px;
          text-align: center;
          margin: 0;
        }
        .service-list {
          margin-top: 55px;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          // 服务
          .service-item {
            width: 282px;
            height: 360px;
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            border-radius: 20px;
            margin-right: 24px;
            img {
              width: 100%;
              height: 240px;
            }
            .service-text-box{
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex: 1;
            }
            span {
              font-size: 16px;
              color: #666;
            }
            .service-title {
              display: inline-block;
              font-size: 24px;
              line-height: 33.6px;
              margin-bottom: 7px;
              color: #333;
            }
          }
          .service-item:nth-child(4) {
            margin-right: 0;
          }
          // 业务规模
          .grade-item {
            width: 384px;
            height: 310px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 24px;
            img {
              width: 160px;
              height: 160px;
              margin: 12px 0 20px 0;
            }
            .number {
              display: inline-block;
              font-size: 42px;
              line-height: 42px;
              font-weight: bold;
              color: #FF9600;
            }
          }
          .grade-item:nth-child(3) {
            margin-right: 0;
          }
          // 合作伙伴
          .partner-item {
            width: 196px;
            height: 64px;
            margin: 0px 55px 0 0;
            text-align: center;
            line-height: 64px;
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 18px;
              color: #999;
            }
          }
          .partner-item:nth-child(5n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
