<template>
  <div class="home">
    <div class="main-content">
      <Banner />
      <Platform />
      <Service />
      <Partner />
      <About />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Banner from '../components/home/banner.vue'
import Platform from '../components/home/platform.vue'
import Service from '../components/home/service.vue'
import Partner from '../components/home/partner.vue'
import About from '../components/home/about.vue'
export default {
  name: 'Home',
  components: {
    Banner,
    Platform,
    Service,
    Partner,
    About
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      isMaxScreen: state => {
        return state.isMaxScreen
      }
    })
  },
  methods: {
    // 跳转外链
    openUrl (url) {
      window.open(url)
    }
  }
}
</script>
<style scoped lang="less">
@media (max-width: 980px) {
  .home {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    background: #F2F5F8;
    .content-item {
      display: flex;
      flex-direction: column;
      .content-items {
        padding-top: 60px;
        box-sizing: border-box;
        p {
          margin: 0;
          padding: 0;
          text-align: left;
          font-size: 32px;
          font-weight: 500;
        }
        .title {
          margin-bottom: 24px;
        }
        .text {
          display: inline-block;
          color: #999;
          font-size: 24px;
          margin-bottom: 11px;
          text-align: left;
        }
        .image-list {
          margin-top: 44px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          img {
            width: 226px;
            height: 176px;
          }
        }
        .service-list {
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          // 服务
          .service-item {
            width: 167px;
            height: 260px;
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            border-radius: 20px;
            margin-right: 12px;
            img {
              width: 100%;
              height: 240px;
            }
            .service-title {
              display: inline-block;
              font-size: 24px;
              line-height: 24px;
              margin: 16px 0 0 0;
            }
          }
          .service-item:nth-child(4) {
            margin-right: 0;
          }
          // 业务规模
          .grade-item {
            width: 384px;
            height: 310px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 24px;
            img {
              width: 160px;
              height: 160px;
              margin: 12px 0 20px 0;
            }
            .grade-title {
              font-size: 34px;
              color: #333;
              margin-bottom: 12px;
            }
            .number {
              display: inline-block;
              font-size: 42px;
              line-height: 42px;
              font-weight: bold;
              color: #FF9600;
            }
          }
          .grade-item:nth-child(3) {
            margin-right: 0;
          }
          // 合作伙伴
          .partner-item {
            width: 196px;
            height: 64px;
            margin: 0px 55px 0 0;
            text-align: center;
            line-height: 64px;
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 18px;
              color: #999;
            }
          }
          .partner-item:nth-child(5n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media (min-width: 981px) {
  .home {
    width: 100%;
    .main-content {
      width: 100%;
      .white {
        background: white;
      }
      .gray {
        background: #F6F6F6;
      }
      .content-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .content-items {
        width: 1200;
        padding: 80 0 100;
        box-sizing: border-box;
        .title {
          font-size: 36;
          font-weight: 500;
          color: #111111;
          line-height: 36;
          text-align: center;
          margin: 0;
        }
        .text {
          display: inline-block;
          font-size: 22;
          margin-top: 20;
        }
        .image-list {
          margin-top: 44;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          img {
            width: 384;
            height: 300;
          }
        }
        .service-list {
          margin-top: 55;
          display: flex;
          flex-wrap: wrap;
          align-content: space-between;
          // justify-content: space-between;
          // 服务
          .service-item {
            width: 282;
            height: 360;
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            border-radius: 20;
            margin-right: 24;
            img {
              width: 100%;
              height: 240;
            }
            .service-title {
              display: inline-block;
              font-size: 24;
              line-height: 24;
              margin: 28 0 17 0;
            }
          }
          .service-item:nth-child(4) {
            margin-right: 0;
          }
          // 业务规模
          .grade-item {
            width: 384;
            height: 310;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 24;
            img {
              width: 160;
              height: 160;
              margin: 12px 0 20px 0;
            }
            .grade-title {
              font-size: 34;
              color: #333;
              margin-bottom: 12;
            }
            .number {
              display: inline-block;
              font-size: 42;
              line-height: 42;
              font-weight: bold;
              color: #FF9600;
            }
          }
          .grade-item:nth-child(3) {
            margin-right: 0;
          }
          // 合作伙伴
          .partner-item {
            width: 196;
            height: 64;
            margin: 0 55 0 0;
            text-align: center;
            line-height: 64;
            img {
              width: 100%;
              height: 100%;
            }
            span {
              font-size: 18;
              color: #999;
            }
          }
          .partner-item:nth-child(5n) {
            margin-right: 0;
          }
        }
        .partner-list {
          height: 370;
        }
      }
    }
  }
}
</style>
