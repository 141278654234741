<template>
  <!-- About Us -->
  <div class="content-item gary">
    <div class="content-items">
      <p class="title">About Us</p>
      <div class="contentList">
        <span class="content-item" v-for="(item, index) in contentList" :key="index">
          {{ item.content }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      contentList: [
        {
          content:
            'eSwop adheres to the company value concepts of 「 Honest, Customer First and Result Oriented 」.'
        },
        {
          content:
            "Being users' needs oriented, we provide users with high-return products and services, and create the greatest value for the Company."
        },
        {
          content:
            'eSwop has a professional digital product quality inspection team and a data technology processing team, and is committed to further improving the formulation of standards for recycling, inspection and service based on advanced technology and professional service, in order to improve the level of inspection and service standards of the recycling industry and provide users with sincere, safe, reliable, professional and efficient services.'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      isMaxScreen: state => {
        return state.isMaxScreen;
      }
    })
  },
  methods: {}
};
</script>

<style lang="less" scoped>
@media (max-width: 980px) {
  .home {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    .content-item {
      display: flex;
      flex-direction: column;
      .content-items {
        padding-top: 55px;
        box-sizing: border-box;
        text-align: left;
        p {
          margin: 0;
          padding: 0;
          text-align: left;
          font-size: 32px;
        }
        .title {
          margin-bottom: 24px;
          font-size: 32px;
          line-height: 32px;
          color: #333;
          font-weight: 550;
        }
        .contentList {
          width: 100%;
          border-radius: 20px;
          padding: 20px;
          margin-bottom: 20px;
          box-sizing: border-box;
          background: #fff url('../../assets/image/about/about-bg.png') no-repeat 100% 100%;
          background-size: 227px 263px;
          .content-item {
            width: 100%;
            font-size: 24px;
            line-height: 40px;
            margin-bottom: 36px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 981px) {
  .home {
    width: 100%;
    .main-content {
      width: 100%;
      .white {
        background: white;
      }
      .gray {
        background: #f6f6f6;
      }
      .content-item {
        display: flex;
        flex-direction: column;
      }
      .content-items {
        width: 1200px;
        padding: 90px 0;
        box-sizing: border-box;
        text-align: left;
        .title {
          font-size: 36px;
          font-weight: 500;
          color: #111111;
          line-height: 36px;
          text-align: center;
          margin: 0;
        }
        .text {
          display: inline-block;
          font-size: 22px;
          margin-top: 20px;
        }
        .contentList {
          margin-top: 40px;
          height: 300px;
          background: url('../../assets/image/about/about-bg.png') no-repeat 100% 100%;
          background-size: 227px 263px;
          .content-item {
            width: 100%;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 36px;
          }
          .content-item:nth-child(3) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
